import React from 'react'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { SavedWrapper } from './OrderDetails.styles'
// Utils
import { formatCurrency } from '../../utils/i18n/stores'

const TotalSavings = ({ savings, isPcMessage }) => {
  const {
    langAndCountry,
    prismicData: { prismicCartPage, prismicRegisterPage },
  } = usePrismic()

  const initialText = prismicCartPage.you_save_on_your_order[0].text
  let replacedHTML = initialText.replace(
    '{savedAmount}',
    `<span>${formatCurrency(langAndCountry, savings)}</span>`
  )
  replacedHTML = replacedHTML.replace(
    '{userType}',
    isPcMessage
      ? prismicRegisterPage.preferred_customer[0].text
      : prismicRegisterPage.ambassador
  )

  if (!savings) {
    return null
  }

  return (
    <>
      <SavedWrapper
        data-qa="saved"
        dangerouslySetInnerHTML={{ __html: replacedHTML }}
      />
    </>
  )
}

export default TotalSavings
