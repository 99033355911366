import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import {
  AddCoupon,
  CartProductCard,
  EventWarningModal,
  Loading,
  Nexio3dsModal,
  OrderConfirmation,
  OrderDetails,
  OrderFailed,
  PageHeader,
  PaymentDetails,
  PayPalButton,
  PcAgreement,
  PcOffer,
  Primary as PrimaryButton,
  Seo,
  ShippingDetails,
  RenewalModalForAmb,
  UkModal,
} from '../components'
// Styles
import { Wrapper, Container, StyledSpan } from '../styles/common'
// Context
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
// Hooks
import useSetShipping from '../hooks/useSetShipping'
// Utils
import { OUT_OF_STOCK } from '../utils/productHelpers'
import analytics from '../utils/analytics'
import TotalSavings from '../components/Cart/TotalSavings'
import { completeNexioTransaction } from '../utils/cartHelpers'
import { assertNexioParams, getNexioParams } from '../utils/3dsHelpers'
// Services
import { auth } from '../services/firebase'
import { QService } from '../services/q-services'
import * as Sentry from '@sentry/react'
import { formatParagraph } from '../utils/prismicUtils'
import { geniusSkus } from '../utils/constants'

const initialCartState = {
  areTermsAccepted: false,
  orderError: null,
  orderNumber: null,
  orderStatus: null,
  receiveSmsEmail: false,
  showConfirmation: false,
  showOrderError: false,
  pendingTransactionUid: null,
}

const CartPage = () => {
  const [
    {
      areTermsAccepted,
      orderError,
      orderNumber,
      orderStatus,
      receiveSmsEmail,
      showConfirmation,
      showOrderError,
    },
    setCartState,
  ] = useState(initialCartState)
  const [nexio3DsUrl, setNexio3DsUrl] = useState('')
  const [showNexio3dsModal, setShowNexio3dsModal] = useState(false)
  const [nexioRedirectData, setNexioRedirectData] = useState({})
  const [isRenewalPending, setIsRenewalPending] = useState(false)
  const [isUkModalOpen, setIsUkModalOpen] = useState(false)
  const [showEventWarningModal, setShowEventWarningModal] = useState(false)
  const [isCancellingOrder, setIsCancellingOrder] = useState(false)

  const {
    storeCountryCode,
    prismicData: {
      prismicGeneral,
      prismicCartPage: {
        card_ending_in,
        continue_to_checkout,
        header,
        loading_cart,
        place_order,
        please_add_a_payment_method,
        please_add_shipping,
        processing_order,
        shipping_address_error,
        shipping_to,
        thanks_for_shopping_with,
        title,
        which_address_should_we_use,
        cart_max,
        hide_paypal_button,
        disallow_retail,
        cancelling_order,
        cancelled_3ds,
        cancelled_status_3ds,
        terms_of_use,
      },
    },
  } = usePrismic()

  const {
    expiredSubscription,
    isAuthenticated,
    isEventSite,
    isVipSite,
    magentoUser,
    qUser,
    qUserUpline,
    userType,
    getSelectedCard,
    handleRefreshSite,
    updateQUser,
    showRenewalModalForAmb,
    setShowRenewalModalForAmb,
    manageReferral: { isEnrollmentComplete, isReferral, referralData },
    cardsOnFile,
  } = useAuthContext()

  const {
    buildCart,
    cartData,
    isAmbOfferInCart,
    isRenewalOfferInCart,
    isPcOfferInCart,
    isPlacingOrder,
    isShippingSet,
    manageCart: { placeOrder, doesCartHaveCBD, placePayPalOrder },
    handleGetCartData,
    setIsPlacingOrder,
    upgradeUserAddPcFee,
  } = useCartContext()

  const { shippingAddress, shippingMethod } = useSetShipping(!!orderNumber)
  const { lastFourDigits, creditCardType } = getSelectedCard()

  const handleOrderError = (error, status) => {
    setCartState(prev => ({
      ...prev,
      orderError: error,
      orderStatus: status,
      showOrderError: true,
    }))
  }

  const reAddAuthHeader = () => {
    const user = auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    QService.setToken(token)
  }

  const confirmTransaction = () => {
    const {
      nexioStatus,
      nexioTransactionUid,
      nexioMOrderNumber,
      nexioError,
      nexioErrorMessage,
      gatewayStatus,
    } = getNexioParams()
    if (nexioError && nexioErrorMessage) {
      setIsPlacingOrder(false)
      setIsCancellingOrder(true)
      completeNexioTransaction(nexioTransactionUid, true)
        .then(() => {
          setIsCancellingOrder(false)
          setCartState(prev => ({ ...prev, orderNumber: nexioMOrderNumber }))
          handleOrderError(nexioErrorMessage, gatewayStatus)
        })
        .catch(() => {
          setIsCancellingOrder(false)
          setCartState(prev => ({ ...prev, orderNumber: nexioMOrderNumber }))
          handleOrderError(nexioErrorMessage, gatewayStatus)
        })
    } else {
      completeNexioTransaction(nexioTransactionUid, false)
        .then(() => {
          handleGetCartData()
          setIsPlacingOrder(false)
          setCartState(prev => ({
            ...prev,
            orderNumber: nexioMOrderNumber,
            showConfirmation: true,
          }))
        })
        .catch(error => {
          console.log(error)
        })
    }
    window.sessionStorage.removeItem('nexio3ds')
  }

  useEffect(() => {
    if (
      auth.currentUser &&
      window.sessionStorage.getItem('nexio3ds') &&
      assertNexioParams()
    ) {
      reAddAuthHeader()
      confirmTransaction()
    }
  }, [auth.currentUser])

  useEffect(() => {
    if (isEventSite) {
      setShowEventWarningModal(true)
    }
  }, [isEventSite])

  const handleNexio3dsModalClose = () => {
    setShowNexio3dsModal(false)
    setNexio3DsUrl('')
    setIsCancellingOrder(true)
    completeNexioTransaction(nexioRedirectData.pendingTransactionUid, true)
      .then(() => {
        handleGetCartData()
        setCartState(prev => ({ ...prev, orderNumber: orderNumber }))
        setIsCancellingOrder(false)
        handleOrderError(
          { message: cancelled_3ds[0].text },
          cancelled_status_3ds[0].text
        )
      })
      .catch(() => {})
  }

  const handleNexio3dsModalYes = () => {
    setShowNexio3dsModal(false)
    window.sessionStorage.setItem('nexio3ds', 'true')
    window.location.href = nexio3DsUrl
    setIsPlacingOrder(true)
    setNexio3DsUrl('')
  }

  const handleSuccess = () => {
    localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
    analytics('purchase')
    setCartState(prev => ({
      ...prev,
      showConfirmation: true,
    }))
  }

  const handleEventWarningClose = () => {
    setShowEventWarningModal(false)
  }

  const { currency = '', value: amount = 0 } =
    cartData?.prices?.grand_total ?? {}

  const taxValue =
    cartData?.prices?.subtotal_including_tax?.value -
      cartData?.prices?.subtotal_excluding_tax?.value || 0

  const shippingTotal = shippingMethod ? shippingMethod?.amount?.value ?? 0 : 0

  const discounts = cartData?.prices?.discounts ?? []

  const discountAmount = discounts.reduce((acc, x) => x.amount.value + acc, 0)
  const onlyGenius = cartData?.items?.every(prod =>
    geniusSkus.includes(prod.product.sku)
  )

  const conditionalAmount =
    isPcOfferInCart || isAmbOfferInCart
      ? cartData?.wholesale_total - discountAmount + taxValue
      : amount

  const cartTotal = (conditionalAmount + shippingTotal).toFixed(2).toString()

  const onPlaceOrder = async () => {
    setIsRenewalPending(true)
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data)
    }

    if (isAmbOfferInCart && !isEnrollmentComplete) {
      alert(
        prismicGeneral.msg_please_complete_your_enrollment_to_purchase_this_offer
      )
      navigate('/enrollment')
      return
    }
    if (!isShippingSet) {
      toast.error(shipping_address_error[0].text)
      return
    }
    if (!lastFourDigits) {
      toast.error(please_add_a_payment_method[0].text)
      return
    }

    window.scrollTo(0, 0) // scroll to top of page
    let { orderNumber, orderError, status, redirectData } = await placeOrder(
      setIsPlacingOrder,
      cartData,
      getSelectedCard,
      buildCart,
      handleGetCartData
    )
    setCartState(prev => ({ ...prev, orderNumber }))
    if (orderError) {
      handleOrderError(orderError, status)
    } else if (redirectData) {
      setNexio3DsUrl(redirectData.redirectUrl)
      setNexioRedirectData(redirectData)
    } else {
      handleSuccess()
    }
  }

  const handlePlaceOrder = () => {
    if (
      storeCountryCode === 'gb' &&
      isAmbOfferInCart &&
      Number(cartTotal) >= 200
    ) {
      return setIsUkModalOpen(true)
    }
    onPlaceOrder()
  }

  // paypal
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  const isUkDisallowed =
    storeCountryCode === 'gb' && isAmbOfferInCart && Number(cartTotal) >= 200
  useEffect(() => {
    if (!cartData) return
    const hasCBD = doesCartHaveCBD(cartData)
    setShowPayPalButton(!hasCBD && !hide_paypal_button && !isUkDisallowed)
  }, [cartData, storeCountryCode])

  // nexio3ds
  useEffect(() => {
    if (!nexio3DsUrl) return
    setShowNexio3dsModal(true)
  }, [nexio3DsUrl])

  const handlePayPalClick = async () => {
    setIsRenewalPending(true)
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data).then(() => {
        // handleSetUserState({ isEnrollmentComplete: true })
      })
    }
  }

  const createPayPalOrder = (_, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: currency,
              value: cartTotal,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then(orderId => {
        Sentry.captureMessage(
          `PayPal Order ID: ${orderId} by ${qUser.emailAddress}`
        )
        return orderId
      })
  }

  const handlePayPalApproved = async (data, actions) => {
    return actions.order.authorize().then(async () => {
      window.scrollTo(0, 0) // scroll to top of page
      let { orderNumber, orderError, status } = await placePayPalOrder(
        setIsPlacingOrder,
        cartData,
        buildCart,
        data?.orderID,
        handleGetCartData
      )
      setCartState(prev => ({ ...prev, orderNumber }))
      if (orderError) handleOrderError(orderError, status)
      else {
        localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
        analytics('purchase')
        setCartState(prev => ({
          ...prev,
          showConfirmation: true,
        }))
      }
    })
  }

  const onPayPalError = error => {
    if (error) {
      handleOrderError(
        error,
        prismicGeneral.error_paypal_failed_to_process_order
      )
      Sentry.captureException(error)
    }
  }

  const isEventOrVip = isEventSite || isVipSite

  const shippingTo = () => {
    if (isEventOrVip) {
      return (
        <StyledSpan bold red>
          {prismicGeneral.msg_event_pickup}
        </StyledSpan>
      )
    }
    let shippingText = which_address_should_we_use[0].text
    if (!isShippingSet && !magentoUser?.addresses?.length)
      shippingText = please_add_shipping[0].text
    if (isShippingSet && shippingAddress) {
      shippingText = `${shippingAddress?.street[0]}`
    }

    return (
      <Link to="/shipping-info">
        <StyledSpan bold>{shippingText}</StyledSpan>
      </Link>
    )
  }

  const cardInfo = () => {
    let cardText =
      lastFourDigits && cardsOnFile.length > 0
        ? lastFourDigits
        : please_add_a_payment_method[0].text
    return (
      <Link to="/payment-info">
        <StyledSpan bold>{cardText}</StyledSpan>
      </Link>
    )
  }

  const handleAgreementChecked = (e, { name }) => {
    name === 'smsEmail'
      ? setCartState(prev => ({ ...prev, receiveSmsEmail: !receiveSmsEmail }))
      : setCartState(prev => ({ ...prev, areTermsAccepted: !areTermsAccepted }))
  }

  const isAProductOutOfStock = cartData?.items?.some(
    ({ product: { stock_status } }) => stock_status === OUT_OF_STOCK
  )

  const shoppingWith = () => {
    if (!isAuthenticated) {
      if (isReferral && referralData?.ambassadorName)
        return referralData?.ambassadorName
      return 'Q Sciences'
    }
    if (qUserUpline?.displayName === 'Par Partners LLC') return 'Q Sciences'

    return qUserUpline?.displayName
  }

  //get total pv from the cartData
  const totalPV = (): number => {
    if (!cartData) return 0
    return cartData?.items?.reduce((acc, item) => {
      return acc + parseInt(item?.product.pv) * item?.quantity
    }, 0)
  }

  const shouldShowSavings =
    (!disallow_retail && isPcOfferInCart) ||
    (!disallow_retail &&
      isAmbOfferInCart &&
      userType === 'RETAIL' &&
      cartData?.total_wholesale_savings) ||
    (!disallow_retail && userType !== 'RETAIL' && cartData?.total_savings)

  const shouldHidePcOffer =
    isPcOfferInCart ||
    isAmbOfferInCart ||
    userType !== 'RETAIL' ||
    isVipSite ||
    isEventSite

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  const shouldShowShipping = !isEventOrVip

  // amb renewals
  useEffect(() => {
    const shouldShowRenewalForAmb =
      expiredSubscription !== null &&
      userType === 'AMBASSADOR' &&
      isAuthenticated &&
      !isRenewalOfferInCart &&
      !isRenewalPending
    setShowRenewalModalForAmb(shouldShowRenewalForAmb)
  }, [expiredSubscription, userType, isAuthenticated, isRenewalOfferInCart])

  if (!cartData)
    return <Loading loading={true} message={loading_cart[0].text} />

  if (isPlacingOrder)
    return (
      <Loading loading={isPlacingOrder} message={processing_order[0].text} />
    )

  if (isCancellingOrder)
    return (
      <Loading loading={isCancellingOrder} message={cancelling_order[0].text} />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <>
          <PageHeader exitRoute="/">{header[0].text}</PageHeader>
          <Container padded>
            {userType === 'AMBASSADOR' ? (
              <StyledSpan data-qa="ambName" isTitle>
                {qUser?.displayName}
              </StyledSpan>
            ) : (
              <StyledSpan>
                {thanks_for_shopping_with[0].text}{' '}
                <StyledSpan data-qa="uplineName" isTitle>
                  {shoppingWith()}
                </StyledSpan>
              </StyledSpan>
            )}
            {isAuthenticated ? (
              <>
                <StyledSpan data-qa="shippingAddress">
                  {shipping_to[0].text} {shippingTo()}
                </StyledSpan>
                <StyledSpan data-qa="cardInfo">
                  {card_ending_in[0].text} {cardInfo()}
                </StyledSpan>
              </>
            ) : null}
            <OrderDetails
              {...cartData}
              shipping={shippingMethod}
              showShippingAndTax={isAuthenticated}
              showWholesaleTotal={isPcOfferInCart || isAmbOfferInCart}
              shippingTotal={shippingTotal}
              taxValue={taxValue}
              totalPV={totalPV()}
              shippingAddress={shippingAddress}
            />
            {shouldShowSavings ? (
              <TotalSavings
                savings={
                  isPcOfferInCart || isAmbOfferInCart
                    ? cartData.total_wholesale_savings
                    : cartData.total_savings
                }
                isPcMessage={isPcOfferInCart || userType === 'PREFERRED'}
              />
            ) : null}
            {isAuthenticated && <AddCoupon />}
          </Container>
          <Container padded>
            {cartData?.items?.map(item => (
              <div key={item.uid}>
                <CartProductCard item={item} />
              </div>
            ))}
          </Container>
          {shouldHidePcOffer ? null : (
            <PcOffer addPcOfferToCart={upgradeUserAddPcFee} />
          )}
          {isAuthenticated ? (
            <>
              {shouldShowShipping ? (
                <ShippingDetails
                  address={shippingAddress}
                  showAddress={isShippingSet}
                />
              ) : null}
              <PaymentDetails
                ccType={creditCardType}
                lastFour={lastFourDigits}
              />
            </>
          ) : null}
          {isPcOfferInCart && isAuthenticated ? (
            <PcAgreement
              receiveSmsEmail={receiveSmsEmail}
              areTermsAccepted={areTermsAccepted}
              handleChecked={handleAgreementChecked}
            />
          ) : null}
          <Container style={{ marginTop: 'auto' }} align="center">
            {isAuthenticated ? (
              <>
                {showPayPalButton &&
                  cartData?.shipping_addresses?.length > 0 && (
                    <PayPalButton
                      onClick={handlePayPalClick}
                      createOrder={createPayPalOrder}
                      onApprove={handlePayPalApproved}
                      onError={onPayPalError}
                      currency={currency}
                      amount={cartTotal}
                      disabled={
                        isAProductOutOfStock ||
                        (isAmbOfferInCart && !isEnrollmentComplete) ||
                        (isPcOfferInCart && !areTermsAccepted) ||
                        (disallow_retail &&
                          !isAmbOfferInCart &&
                          !isPcOfferInCart &&
                          userType === 'RETAIL') ||
                        cartData?.items?.length === 0 ||
                        (cart_max &&
                          cartData?.prices?.subtotal_excluding_tax?.value >
                            cart_max)
                      }
                    />
                  )}
                <PrimaryButton
                  style={{ margin: '2em 0 1em' }}
                  onClick={handlePlaceOrder}
                  content={place_order[0].text}
                  disabled={
                    cartData?.shipping_addresses?.length === 0 ||
                    !lastFourDigits ||
                    isAProductOutOfStock ||
                    (isPcOfferInCart && !areTermsAccepted) ||
                    (disallow_retail &&
                      !isAmbOfferInCart &&
                      !isPcOfferInCart &&
                      userType === 'RETAIL') ||
                    cartData?.items?.length === 0 ||
                    (!onlyGenius &&
                      cart_max &&
                      cartData?.prices?.subtotal_excluding_tax?.value >
                        cart_max)
                  }
                />
                <Container style={{ marginBottom: '2em', width: '70%' }}>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: formatParagraph(terms_of_use[0]),
                    }}
                  />
                </Container>
                {showAutoShip ? (
                  <PrimaryButton
                    onClick={() => navigate('/subscriptions')}
                    content={prismicGeneral.btn_manage_subscription}
                    style={{
                      marginTop: '0',
                    }}
                  />
                ) : null}
              </>
            ) : (
              <PrimaryButton
                style={{ margin: '1em 0' }}
                onClick={() => navigate('/login')}
                content={continue_to_checkout[0].text}
              />
            )}
          </Container>
        </>
        {showConfirmation && orderNumber ? (
          <OrderConfirmation
            orderNumber={orderNumber}
            email={cartData.email}
            isEventSite={isEventOrVip}
            shouldRefresh={
              isEnrollmentComplete || areTermsAccepted || isRenewalPending
            }
            handleRefresh={() => {
              handleRefreshSite()
              setIsRenewalPending(false)
            }}
          />
        ) : null}
        {showOrderError && orderNumber ? (
          <OrderFailed
            orderNumber={orderNumber}
            orderStatus={orderStatus}
            userName={qUser?.displayName}
            errorData={orderError}
          />
        ) : null}
        {showRenewalModalForAmb && (
          <RenewalModalForAmb open={showRenewalModalForAmb} disableOnClose />
        )}
        <EventWarningModal
          open={showEventWarningModal}
          onClose={handleEventWarningClose}
        />
        <Nexio3dsModal
          open={showNexio3dsModal}
          onClose={handleNexio3dsModalClose}
          onYes={handleNexio3dsModalYes}
        />
        {isUkModalOpen && (
          <UkModal
            open={isUkModalOpen}
            onClose={() => setIsUkModalOpen(false)}
            amount={cartTotal}
            onSubmit={onPlaceOrder}
          />
        )}
      </Wrapper>
    </>
  )
}

export default CartPage
